/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import type {ColumnsType, SorterResult} from "antd/es/table/interface";
import InstitutionUserService from "../../services/InstitutionUserService";
import { LoadingOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { TablePaginationConfig, TableProps, Tag } from 'antd'
import { Button, Card, Col, DatePicker, Form, Input, Row, Spin, Table, message } from 'antd'
import { downloadExcel } from '../../utils/downloadExcel'
import FileChart from '../../assets/img/file-chart.svg'
import { generateChartDataMFinance } from '../../utils/generateChartData'


const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#37762F' }} spin />
const { RangePicker } = DatePicker
interface DataType {
  key: React.Key
  bank_code: string
  transaction_reference: string
  debit_account_number: string
  debit_account_name: string
  beneficiary_account_name: string
  beneficiary_bank_name: string
  beneficiary_account_number: string
  transaction_currency: string
  amount: number
  reason: string
  status: string
  created_at: string;
  updated_at: string;
  column: string;
  totalPayments: string;
  amountRequestedToBeRefund: string;
  amountRequestedToBeRefundedInProgressStatus: string;
  TotalNumberOfApplicantRequestedRefund: string;
  notRequestedBidders: string;
}
const PendingBiddersRefundRequest: React.FC = () => {
  const [form] = Form.useForm()
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
  const navigate = useNavigate()
  const [filterStatus, setfilterStatus] = useState('')
  const [clear, setClear] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [refundRequestList, setRefundRequestList] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({})
  const [paginating, setPaginating] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [stats, setStats] = React.useState<DataType | null>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    // Calculate total amount whenever refundRequestList changes
    const total = refundRequestList.reduce((acc, curr) => curr.amount, 0);
    setTotalAmount(total);
  }, [refundRequestList]);

  React.useEffect(() => {
    InstitutionUserService.RefundRequestStats()
      .then((res: any) => {
        setStats(res.data); // assuming the stats are in res.data
      })
      .catch((err) => {
        message.error(err?.message);
      });
  }, []);

  useEffect(() => {
    OnRefundRequestSearch()
    // eslint-disable-next-line
  }, [clear, paginating.current, paginating.pageSize, fromDate, toDate])

  const OnRefundRequestSearch = (event?: any): void => {
    setLoading(true)
    InstitutionUserService.GetRefundRequestList(
      paginating.pageSize as number,
      paginating.current as number,
      event?.target?.value,
      fromDate,
      toDate)
      .then((res: any) => {
        if (res.status === 200) {
          setRefundRequestList(res?.data)
          setPaginating((prev) => ({
            ...prev,
            total: res?.meta?.total
          }))
          setLoading(false)
        }
      })
      .catch((err: any) => {
        if (err?.message !== 'Operation canceled due to new request.') {
          void message.error(err?.message)
        }
        setLoading(false)
      })
  }

  const handleChange: TableProps<DataType>['onChange'] = (pagination, _filters, sorter) => {
    setPaginating(pagination)
    setSortedInfo(sorter as SorterResult<DataType>)
  }

  let filter = ''
  if (filterStatus) {
    filter = filter + 'Type'
  }
  if (fromDate) {
    filter = filter === '' ? filter + 'From Date' : filter + ', From Date'
  }
  if (toDate) {
    filter = filter === '' ? filter + 'To Date' : filter + ', To Date'
  }

  const updateRefundRequestStatus = async (): Promise<void> => {
    try {
      const refundRequests = refundRequestList.map(item => ({ transaction_reference: item.transaction_reference }));
      const response = await InstitutionUserService.updateRefundRequestStatus({ RefundRequests: refundRequests });

      if (response.status === 200) {
        // Handle success
      } else {
        // Handle unexpected response status
      }
    } catch (error) {
      // Handle error
    }
  };

  const columnsToDownload = [
    'bank_code',
    'transaction_reference',
    'debit_account_number',
    'debit_account_name',
    'beneficiary_bank_name',
    'beneficiary_account_name',
    'beneficiary_account_number',
    'amount',
    'transaction_currency',
    'reason'
  ];

  const convertToCSV = (data: { [x: string]: any; }[]) => {
    const header = columnsToDownload.join(',') + '\n';
    const rows = data.map((row: { [x: string]: any; }) => {
      const rowData = columnsToDownload.map((col, index) => {
        // Wrap bank code column in double quotes
        if (col === 'bank_code') {
          return `"${row[col]}"`;
        } else {
          return row[col];
        }
      }).join(',');
      return rowData;
    }).join('\n');
    return header + rows;
  };

  const downloadCSV = (csvContent: BlobPart, fileName: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    if ((navigator as any).msSaveBlob) { // IE 10+
      (navigator as any).msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleDownloadExcel = async (): Promise<void> => {
    try {
      const csvContent = convertToCSV(refundRequestList);
      downloadCSV(csvContent, 'BankTransfersTemplate.csv');
      await updateRefundRequestStatus();
    } catch (error) {
      // Handle error
      void message.warning("Error occurred while downloading excel and updating refund request status:");
    }
  };

  const clearFilter = (): void => {
    setfilterStatus('')
    form.setFieldValue('filterStatus', '')
    form.setFieldValue('range_picker', ['', ''])
    setFromDate('')
    setToDate('')
    setClear(true)
  }
  const columns: ColumnsType<DataType> = [
    {
      title: 'Bank Code',
      dataIndex: 'bank_code',
      render: (value: any, record: any) => (
        <span>{record?.bank_code}</span>
      ),
      key: 'bank_code',
      sorter: (a: any, b: any) => a?.bank_code.localeCompare(b.bank_code),
      sortOrder: sortedInfo.columnKey === 'bank_code' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Transaction Reference',
      dataIndex: 'transaction_reference',
      key: 'transaction_reference',
      sorter: (a: any, b: any) => a?.transaction_reference.localeCompare(b?.transaction_reference),
      sortOrder: sortedInfo?.columnKey === 'transaction_reference' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Debit Account Number',
      dataIndex: 'debit_account_number',
      key: 'debit_account_number',
      sorter: (a: any, b: any) => a?.debit_account_number.localeCompare(b?.debit_account_number),
      sortOrder: sortedInfo?.columnKey === 'debit_account_number' ? sortedInfo?.order : null
    },
    {
      title: 'Debit Account Name',
      dataIndex: 'debit_account_name',
      key: 'debit_account_name',
      sorter: (a: any, b: any) => a.debit_account_name.localeCompare(b.debit_account_name),
      sortOrder: sortedInfo?.columnKey === 'debit_account_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Name',
      dataIndex: 'beneficiary_account_name',
      key: 'beneficiary_account_name',
      sorter: (a: any, b: any) => a?.beneficiary_account_name.localeCompare(b?.beneficiary_account_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_name' ? sortedInfo?.order : null
    },
    {
      title: 'Beneficiary Bank Name',
      dataIndex: 'beneficiary_bank_name',
      key: 'beneficiary_bank_name',
      sorter: (a: any, b: any) => a?.beneficiary_bank_name.localeCompare(b?.beneficiary_bank_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_bank_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Number',
      dataIndex: 'beneficiary_account_number',
      key: 'beneficiary_account_number',
      sorter: (a: any, b: any) => a?.beneficiary_account_number.localeCompare(b?.beneficiary_account_number),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_number' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_text: any, record: any) => {
        const amount = parseInt(record?.amount, 10)
        return amount.toLocaleString()
      },
      sorter: (a: any, b: any) => Number(a?.amount) - Number(b?.amount),
      sortOrder: sortedInfo?.columnKey === 'amount' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Transaction Currency',
      dataIndex: 'transaction_currency',
      key:'transaction_currency',
      sorter: (a: any, b: any) => a.transaction_currency.localeCompare(b.transaction_currency),
      sortOrder: sortedInfo?.columnKey === 'transaction_currency' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key:'reason',
      sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo?.columnKey === 'reason' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: any, record: any) => (
        <Tag color={record?.status === 'RECEIVED REQUEST' ? 'success' : 'error'}>{record?.status} </Tag>
      ),
      key:'status',
      sorter: (a: any, b: any) => a.reason.localeCompare(b.status),
      sortOrder: sortedInfo?.columnKey === 'status' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className="w-[60%] flex justify-center">
          <i
            className="far fa-eye cursor-pointer"
            onClick={() => {
              navigate('/view-refund-request', { state: record })
            }}
          ></i>
        </div>
      )
    }
  ]
  // Function to calculate total amount
  return (
    <div className="w-full">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">Pending Refund Request</div>
          <div className="md:w-1/5"></div>
        </div>
      </div>
      <div className="px-7">
        <div className="py-4">
          <Form
            name="normal_login"
            layout="vertical"
            className="login-form p-10"
            initialValues={{ remember: true }}
            form={form}
          >
            <Card className="bg-slate-100">
              <Row gutter={16}>
                <Col xs={24} xl={18}>
                  <Form.Item name="range_picker">
                    <RangePicker
                      size="large"
                      className="w-full"
                      allowClear
                      onChange={(value, valueString) => {
                        setFromDate(valueString[0])
                        setToDate(valueString[1])
                      }}
                    />
                  </Form.Item>
                  {filter !== ''
                    ? (
                      <div className="flex justify-between pb-2.5 md:pb-0">
                        <div>
                          Applied filters: <span
                          className="font-medium">{filter}</span>
                        </div>
                        <div
                          className="bg-black text-center py-0.5 px-2.5
                   text-white rounded-sm font-medium cursor-pointer"
                          onClick={clearFilter}
                        >
                          Clear X
                        </div>
                      </div>
                    )
                    : (
                      ''
                    )}
                </Col>
                <Col xs={24} xl={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-full font-bold"
                      onClick={handleDownloadExcel}
                      disabled={fromDate.length === 0 || toDate.length === 0}
                    >
                      <div className="float-left">
                        <i className="fa-file-excel"></i>
                      </div>
                      DOWNLOAD
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
        <Card className="bg-white">
          <div className="pt-2.5">
            <div className="flex justify-between">
              <div className="text-xl font-semibold">List of Pending Refund Request</div>
              <div className="md:w-1/4">
                <Input
                  size="large"
                  placeholder="Quick search in the tables"
                  prefix={<ZoomOutOutlined size={12}/>}
                  onChange={(e) => {
                    OnRefundRequestSearch(e)
                  }}
                />
              </div>
            </div>
            <div className="pt-2.5 overflow-auto">
              {loading
                ? (
                  <div className="text-center justify-center align-middle py-4">
                    <Spin indicator={antIcon}/>
                  </div>
                )
                : (
                  <Table
                    columns={columns}
                    dataSource={refundRequestList}
                    pagination={paginating}
                    onChange={handleChange}
                  />
                )}
            </div>
            <div className="font-extrabold">Total
              Amount: {parseInt(String(totalAmount)).toLocaleString()} Rwf</div>
          </div>
        </Card>
      </div>
    </div>
  )
}
export default PendingBiddersRefundRequest
