import { Button, Card, Col, Row, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import { useNavigate } from 'react-router-dom'
import '../../assets/css/custom.css'
import Certificate from '../../assets/img/certificate.svg'
import CheckBook from '../../assets/img/check-book.svg'
import FileChart from '../../assets/img/file-chart.svg'
import InstitutionUserService from '../../services/InstitutionUserService'
import { generateChartDataMFinance, generateChartRefundDataMFinance } from '../../utils/generateChartData'

interface DataType {
  key: React.Key
  amount: number
  reason: string
  status: string
  created_at: string
  updated_at: string
  column: string
  totalPayments: string
  amountRequestedToBeRefund: string
  amountRequestedToBeRefundedInProgressStatus: string
  TotalNumberOfApplicantRequestedRefund: string
  notRequestedBidders: string
}
export const barOptions = {
  chart: {
    title: 'Provided Bid Security Amount vs Digital Payments Amounts per Month',
    subtitle: 'Bid Guarantee, Certified checks - 2023-Now'
  }
}

export const barOptions2 = {
  chart: {
    title: 'Digital Refunded Amounts per Month',
    subtitle: 'Digital Payment - 2023-Now'
  }
}

const FinanceDashboard: React.FC = () => {
  // const navigate = useNavigate()
  const [stats, setStats] = React.useState<Record<string, any>>({})
  const [refundRequestList, setRefundRequestList] = useState<DataType[]>([])
  const [statsRefund, setStatsRefund] = React.useState<Record<string, any>>({})
  const [statsBailiffRefund, setStatsBailiffRefund] = React.useState<Record<string, any>>({})
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalPaid, setTotalPaidAmount] = useState(0)
  const [certifiedCheckAmount, setCertifiedCheckAmount] = useState(0)
  const [bidGuaranteeAmount, setBidGuaranteeAmount] = useState(0)
  const [digitalPaymentAmount, setDigitalPaymentAmount] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toggleModal = () => {
    setModalVisible(!modalVisible)
  } // Added closing parenthesis here

  useEffect(() => {
    // Calculate total amount whenever refundRequestList changes
    const totalPaidAmount = certifiedCheckAmount + bidGuaranteeAmount + digitalPaymentAmount
    setTotalPaidAmount(totalPaidAmount)
  }, [certifiedCheckAmount, bidGuaranteeAmount, digitalPaymentAmount])

  useEffect(() => {
    const total = refundRequestList.reduce((acc, curr) => curr.amount, 0)
    setTotalAmount(total)
  }, [refundRequestList])

  React.useEffect(() => {
    InstitutionUserService.RefundRequestStats()
      .then((res: any) => {
        setStatsRefund(res.data)
      })
      .catch((err) => {
        void message.error(err?.message)
      })

    InstitutionUserService.bailiffRefundRequestStats()
      .then((response: any) => {
        setStatsBailiffRefund(response.data)
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  React.useEffect(() => {
    InstitutionUserService.MFinanceStats()
      .then((res: any) => {
        const chartData = generateChartDataMFinance(res?.data.barData)

        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartData
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  React.useEffect(() => {
    InstitutionUserService.MFinanceRefundStats()
      .then((res: any) => {
        const chartRefundData = generateChartRefundDataMFinance(res?.data.barData)

        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartRefundData
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  useEffect(() => {
    InstitutionUserService.MFinanceStats()
      .then((res: any) => {
        const chartData = generateChartDataMFinance(res?.data.barData)
        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartData
        }))
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const certifiedCheckAmount = parseInt(res?.data?.certified_checks)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const bidGuaranteeAmount = parseInt(res?.data?.bid_guarantees)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const digitalPaymentAmount = parseInt(res?.data?.payments?.totalPayments)

        setCertifiedCheckAmount(isNaN(certifiedCheckAmount) ? 0 : certifiedCheckAmount)
        setBidGuaranteeAmount(isNaN(bidGuaranteeAmount) ? 0 : bidGuaranteeAmount)
        setDigitalPaymentAmount(isNaN(digitalPaymentAmount) ? 0 : digitalPaymentAmount)
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span>Dashboard</span> | <span className="amountInTotal">Total Bid Security Amount paid:
           <span className="amountInTotalFormat">{totalPaid.toLocaleString()} Rwf</span></span>
          </div>
        </div>
      </div>
      <div className="p-7">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={CheckBook} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div>Total Refunded</div>
                  <div>Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        ? (
                          // eslint-disable-next-line max-len
                            (parseInt(statsRefund.totalRefundPayments) + parseInt(statsBailiffRefund.totalPayments)).toLocaleString() + ' Rwf'
                          )
                        : (
                            'Loading...'
                          )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={FileChart} alt="Institution" className=""/>
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div>Pending Request</div>
                  <div>Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        ? (
                          // eslint-disable-next-line max-len
                            (parseInt(statsRefund.amountRequestedToBeRefundedInProgressStatus) + parseInt(statsBailiffRefund.amountRequestedToBeRefundedInProgressStatus)).toLocaleString() + ' Rwf'
                          )
                        : (
                            'Loading...'
                          )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={FileChart} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div>Requested to be Refund</div>
                  <div>Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        ? (
                          // eslint-disable-next-line max-len
                            (parseInt(statsRefund.amountRequestedToBeRefund) + parseInt(statsBailiffRefund.amountRequestedToBeRefund)).toLocaleString() + ' Rwf'
                          )
                        : (
                            'Loading...'
                          )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={6}>
              <Card className="card-border h-full bg-silver-color">
                <div className="text-center">
                  <div className="flex justify-center">
                    <img src={Certificate} alt="Finance" className="" />
                  </div>
                  <div className="pt-2.5 text-2.5 font-extrabold">
                    <div>Bid Security</div>
                    <div>Total Amount Paid in RFW</div>
                    <div className="text-3xl pt-3">
                      <p className="bid-stat-refund">
                        {totalPaid.toLocaleString()}
                      </p>
                    </div>
                    <p>
                      <button onClick={toggleModal}>More...</button>
                    </p>
                  </div>
                </div>
              </Card>
            <Modal
              visible={modalVisible}
              onCancel={toggleModal}
              className="paymentsStats"
              width={800}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row gutter={[16, 16]} style={{ width: '100%', maxWidth: '100%' }}>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={CheckBook} alt="Finance" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Certified Checks</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.certified_checks === null
                                ? 0
                                : parseInt(stats?.certified_checks)?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={FileChart} alt="Institution" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Digital Payments</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.payments?.totalPayments === null
                                ? 0
                                : parseInt(stats?.payments?.totalPayments).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={FileChart} alt="Institution" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Bid Guarantees</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.bid_guarantees === null
                                ? 0
                                : parseInt(stats?.bid_guarantees)?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} xl={12}>
            <Card className="card-border h-full">
              <div className="text-center">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={stats?.chartData}
                  options={barOptions}
                />
              </div>
            </Card>
          </Col>

          <Col xs={24} xl={12}>
            <Card className="card-border h-full">
              <div className="text-center">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={stats?.chartRefundData}
                  options={barOptions2}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FinanceDashboard
