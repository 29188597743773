/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import type {ColumnsType, SorterResult} from "antd/es/table/interface";
import InstitutionUserService from "../../services/InstitutionUserService";
import { LoadingOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { TablePaginationConfig, TableProps, Tag } from 'antd'
import { Button, Card, Col, DatePicker, Form, Input, Row, Tabs, Spin, Table, message } from 'antd'
import TabPane = Tabs.TabPane
import * as XLSX from 'xlsx'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#37762F' }} spin />
const { RangePicker } = DatePicker
interface DataType {
  key: React.Key
  bank_code: string
  transaction_reference: string
  debit_account_number: string
  debit_account_name: string
  beneficiary_account_name: string
  beneficiary_bank_name: string
  beneficiary_account_number: string
  transaction_currency: string
  amount: number
  reason: string
  status: string
  created_at: string;
  updated_at: string;
  totalPayments: string;
  amountRequestedToBeRefund: string;
  amountRequestedToBeRefundedInProgressStatus: string;
  TotalNumberOfApplicantRequestedRefund: string;
  notRequestedBidders: string;
}
const BiddersRefundRequestStatus: React.FC = () => {
  const [form] = Form.useForm()
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
  const navigate = useNavigate()
  const [filterStatus, setfilterStatus] = useState('')
  const [clear, setClear] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [refundRequestList, setRefundRequestList] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false); // State for progress bar visibility
  const [progressPercent, setProgressPercent] = useState(0); // State for progress percentage
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({})
  const [paginating, setPaginating] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [stats, setStats] = React.useState<DataType | null>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [activeTab, setActiveTab] = useState('bidders'); // Default tab
  const [bailiffRefundRequestList, setBailiffRefundRequestList] = useState<DataType[]>([]);

  useEffect(() => {
    handlePageReload();
  }, []);
  useEffect(() => {
    OnRefundRequestSearch();
  }, []);

  const handlePageReload = () => {
    setProgressVisible(true);
    setLoading(true);

    // Simulating data fetching delay
    setTimeout(() => {
      setLoading(false);
      setProgressVisible(false);
    }, 5000);
  };
  useEffect(() => {
    if (activeTab === 'bidders') {
      const total = refundRequestList.reduce((acc, curr) => acc + curr.amount, 0);
      setTotalAmount(total);
    } else if (activeTab === 'bailiffs') {
      const total = bailiffRefundRequestList.reduce((acc, curr) => acc + curr.amount, 0);
      setTotalAmount(total);
    }
  }, [refundRequestList, bailiffRefundRequestList, activeTab]);

  React.useEffect(() => {
    InstitutionUserService.RefundRequestStats()
      .then((res: any) => {
        setStats(res.data);
      })
      .catch((err) => {
        message.error(err?.message);
      });
  }, []);

  const handleChange: TableProps<DataType>['onChange'] = (pagination, _filters, sorter) => {
    setPaginating(pagination)
    setSortedInfo(sorter as SorterResult<DataType>)
  }

  let filter = ''
  if (filterStatus) {
    filter = filter + 'Type'
  }
  if (fromDate) {
    filter = filter === '' ? filter + 'From Date' : filter + ', From Date'
  }
  if (toDate) {
    filter = filter === '' ? filter + 'To Date' : filter + ', To Date'
  }

  const columnsToDownload = [
    'bank_code',
    'transaction_reference',
    'debit_account_number',
    'debit_account_name',
    'beneficiary_account_name',
    'beneficiary_bank_name',
    'beneficiary_account_number',
    'amount',
    'transaction_currency',
    'reason'
  ]

  const convertToExcel = (data: { [x: string]: any }[], maxColumns: number = 10): XLSX.WorkBook => {
    console.log('Converting data to Excel format...');

    // Define columns to be skipped
    const columnsToSkip = ['refund_request_id', 'bailiff_refund_request_id'];

    // Extract headers while excluding columns to be skipped
    const originalColumns = Object.keys(data[0])
      .filter(col => !columnsToSkip.includes(col))
      .slice(0, maxColumns)
      .map(col => col.replace(/_/g, ' ').toUpperCase()); // Remove underscores and convert to uppercase

    console.log('Original Columns:', originalColumns);

    // Prepare data for the worksheet
    const sheetData = data.map(row => {
      const rowData: { [key: string]: any } = {};
      originalColumns.forEach(column => {
        // Convert header back to original format to match data keys
        const columnKey = column.toLowerCase().replace(/ /g, '_');
        rowData[column] = row[columnKey] || '';
      });
      return rowData;
    });

    console.log('Sheet Data:', sheetData);

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(sheetData, { header: originalColumns });

    // Apply styles to the header row
    const headerCellStyle = {
      fill: {
        fgColor: { rgb: '33bbff' }
      },
      font: {
        bold: true
      }
    };

    // Set the header row's style
    ws['!cols'] = originalColumns.map(() => ({ width: 20 })); // Set default column width
    ws['!rows'] = [{ hpx: 20 }]; // Set default row height for header

    originalColumns.forEach((col, i) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: i });
      ws[cellAddress] = { ...ws[cellAddress], s: headerCellStyle };
    });

    // Create a workbook and add the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    console.log('Excel workbook created.');

    return wb;
  };

// Downloads the Excel file
  const downloadExcel = (wb: XLSX.WorkBook, fileName: string) => {
    console.log('Preparing to download Excel file...');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if ((navigator as any).msSaveBlob) { // For IE 10+
      (navigator as any).msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('File download not supported on this browser.');
      }
    }

    console.log('Excel file downloaded.');
  };

// Handle the process of downloading the Excel file
  const handleDownloadExcelFile = async (): Promise<void> => {
    try {
      console.log('Handling download of Excel file...');

      let dataToDownload: { [x: string]: any }[] = [];
      if (activeTab === 'bidders') {
        dataToDownload = refundRequestList;
      } else if (activeTab === 'bailiffs') {
        dataToDownload = bailiffRefundRequestList;
      }

      console.log('Data to download:', dataToDownload);

      const wb = convertToExcel(dataToDownload, 10);
      downloadExcel(wb, 'Refund Request Report.xlsx');
    } catch (error) {
      console.error('Error occurred while downloading Excel:', error);
      message.warning("Error occurred while downloading Excel and updating refund request status.");
    }
  };

  const clearFilter = (): void => {
    setfilterStatus('')
    form.setFieldValue('filterStatus', '')
    form.setFieldValue('range_picker', ['', ''])
    setFromDate('')
    setToDate('')
    setClear(true)
  }

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    OnRefundRequestSearch();
  }, [activeTab, paginating.pageSize, paginating.current, filterStatus, fromDate, toDate]);

  const OnRefundRequestSearch = (event?: any): void => {
    setLoading(true);
    const service = activeTab === 'bidders' ? InstitutionUserService.GetRefundRequestListByStatus : InstitutionUserService.GetBailiffRefundRequestListByStatus;
    service(
      paginating.pageSize as number,
      paginating.current as number,
      event?.target?.value,
      fromDate,
      toDate
    )
      .then((res: any) => {
        if (res.status === 200) {
          if (activeTab === 'bidders') {
            setRefundRequestList(res?.data);
          } else if (activeTab === 'bailiffs') {
            setBailiffRefundRequestList(res?.data);
          }
          setPaginating((prev) => ({
            ...prev,
            total: res?.meta?.total
          }));
          setLoading(false);
        }
      })
      .catch((err: any) => {
        if (err?.message !== 'Operation canceled due to new request.') {
          message.error(err?.message);
        }
        setLoading(false);
      });
  };
  const bailiffsColumns: ColumnsType<DataType> = [
    // Define columns for bidders tab
    // {
    //   title: 'Bank Code',
    //   dataIndex: 'bank_code',
    //   render: (value: any, record: any) => (
    //     <span>{record?.bank_code}</span>
    //   ),
    //   key: 'bank_code',
    //   sorter: (a: any, b: any) => a?.bank_code.localeCompare(b.bank_code),
    //   sortOrder: sortedInfo.columnKey === 'bank_code' ? sortedInfo?.order : null,
    //   ellipsis: true
    // },
    {
      title: 'Transaction Reference',
      dataIndex: 'transaction_reference',
      key: 'transaction_reference',
      sorter: (a: any, b: any) => a?.transaction_reference.localeCompare(b?.transaction_reference),
      sortOrder: sortedInfo?.columnKey === 'transaction_reference' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Debit Account Number',
      dataIndex: 'debit_account_number',
      key: 'debit_account_number',
      sorter: (a: any, b: any) => a?.debit_account_number.localeCompare(b?.debit_account_number),
      sortOrder: sortedInfo?.columnKey === 'debit_account_number' ? sortedInfo?.order : null
    },
    {
      title: 'Debit Account Name',
      dataIndex: 'debit_account_name',
      key: 'debit_account_name',
      sorter: (a: any, b: any) => a.debit_account_name.localeCompare(b.debit_account_name),
      sortOrder: sortedInfo?.columnKey === 'debit_account_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Name',
      dataIndex: 'beneficiary_account_name',
      key: 'beneficiary_account_name',
      sorter: (a: any, b: any) => a?.beneficiary_account_name.localeCompare(b?.beneficiary_account_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_name' ? sortedInfo?.order : null
    },
    {
      title: 'Beneficiary Bank Name',
      dataIndex: 'beneficiary_bank_name',
      key: 'beneficiary_bank_name',
      sorter: (a: any, b: any) => a?.beneficiary_bank_name.localeCompare(b?.beneficiary_bank_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_bank_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Number',
      dataIndex: 'beneficiary_account_number',
      key: 'beneficiary_account_number',
      sorter: (a: any, b: any) => a?.beneficiary_account_number.localeCompare(b?.beneficiary_account_number),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_number' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_text: any, record: any) => {
        const amount = parseInt(record?.amount, 10)
        return amount.toLocaleString()
      },
      sorter: (a: any, b: any) => Number(a?.amount) - Number(b?.amount),
      sortOrder: sortedInfo?.columnKey === 'amount' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Transaction Currency',
      dataIndex: 'transaction_currency',
      key:'transaction_currency',
      sorter: (a: any, b: any) => a.transaction_currency.localeCompare(b.transaction_currency),
      sortOrder: sortedInfo?.columnKey === 'transaction_currency' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key:'reason',
      sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo?.columnKey === 'reason' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: any, record: any) => {
        const tagColorClass = record?.status === 'Downloaded to be Refunded' ? 'custom-tag-info' : 'custom-tag-error';

        return (
          <Tag className={`custom-tag ${tagColorClass}`}>
            {record?.status}
          </Tag>
        );
      },
      key: 'status',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo?.columnKey === 'status' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className="w-[60%] flex justify-center icon-container">
          <i
            className="far fa-eye cursor-pointer icon-style"
            onClick={() => {
              navigate('/view-refund-request', { state: record })
            }}
          ></i>
        </div>
      )
    }
  ];
  const biddersColumns: ColumnsType<DataType> = [
    // {
    //   title: 'Bank Code',
    //   dataIndex: 'bank_code',
    //   render: (value: any, record: any) => (
    //     <span>{record?.bank_code}</span>
    //   ),
    //   key: 'bank_code',
    //   sorter: (a: any, b: any) => a?.bank_code.localeCompare(b.bank_code),
    //   sortOrder: sortedInfo.columnKey === 'bank_code' ? sortedInfo?.order : null,
    //   ellipsis: true
    // },
    {
      title: 'Transaction Reference',
      dataIndex: 'transaction_reference',
      key: 'transaction_reference',
      sorter: (a: any, b: any) => a?.transaction_reference.localeCompare(b?.transaction_reference),
      sortOrder: sortedInfo?.columnKey === 'transaction_reference' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Debit Account Number',
      dataIndex: 'debit_account_number',
      key: 'debit_account_number',
      sorter: (a: any, b: any) => a?.debit_account_number.localeCompare(b?.debit_account_number),
      sortOrder: sortedInfo?.columnKey === 'debit_account_number' ? sortedInfo?.order : null
    },
    {
      title: 'Debit Account Name',
      dataIndex: 'debit_account_name',
      key: 'debit_account_name',
      sorter: (a: any, b: any) => a.debit_account_name.localeCompare(b.debit_account_name),
      sortOrder: sortedInfo?.columnKey === 'debit_account_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Name',
      dataIndex: 'beneficiary_account_name',
      key: 'beneficiary_account_name',
      sorter: (a: any, b: any) => a?.beneficiary_account_name.localeCompare(b?.beneficiary_account_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_name' ? sortedInfo?.order : null
    },
    {
      title: 'Beneficiary Bank Name',
      dataIndex: 'beneficiary_bank_name',
      key: 'beneficiary_bank_name',
      sorter: (a: any, b: any) => a?.beneficiary_bank_name.localeCompare(b?.beneficiary_bank_name),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_bank_name' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Beneficiary Account Number',
      dataIndex: 'beneficiary_account_number',
      key: 'beneficiary_account_number',
      sorter: (a: any, b: any) => a?.beneficiary_account_number.localeCompare(b?.beneficiary_account_number),
      sortOrder: sortedInfo?.columnKey === 'beneficiary_account_number' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_text: any, record: any) => {
        const amount = parseInt(record?.amount, 10)
        return amount.toLocaleString()
      },
      sorter: (a: any, b: any) => Number(a?.amount) - Number(b?.amount),
      sortOrder: sortedInfo?.columnKey === 'amount' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Transaction Currency',
      dataIndex: 'transaction_currency',
      key:'transaction_currency',
      sorter: (a: any, b: any) => a.transaction_currency.localeCompare(b.transaction_currency),
      sortOrder: sortedInfo?.columnKey === 'transaction_currency' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key:'reason',
      sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo?.columnKey === 'reason' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: any, record: any) => {
        const tagColorClass = record?.status === 'Downloaded to be Refunded' ? 'custom-tag-info' : 'custom-tag-error';

        return (
          <Tag className={`custom-tag ${tagColorClass}`}>
            {record?.status}
          </Tag>
        );
      },
      key: 'status',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo?.columnKey === 'status' ? sortedInfo?.order : null,
      ellipsis: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className="w-[60%] flex justify-center icon-container">
          <i
            className="far fa-eye cursor-pointer icon-style"
            onClick={() => {
              navigate('/view-refund-request', { state: record })
            }}
          ></i>
        </div>
      )
    }
  ]

  return (
    <div className="w-full">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid w-full">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">Refund Status(Downloaded Bidders)</div>
          <div className="md:w-1/5"></div>
        </div>
      </div>
      <div className="px-7">
        <div className="py-4">
          <Form
            name="normal_login"
            layout="vertical"
            className="login-form p-10"
            initialValues={{ remember: true }}
            form={form}
          >
            <Card className="bg-slate-100">
              <Row gutter={16}>
                <Col xs={24} xl={18}>
                  <Form.Item name="range_picker">
                    <RangePicker
                      size="large"
                      className="w-full"
                      allowClear
                      onChange={(value, valueString) => {
                        setFromDate(valueString[0])
                        setToDate(valueString[1])
                      }}
                    />
                  </Form.Item>
                  {filter !== ''
                    ? (
                      <div className="flex justify-between pb-2.5 md:pb-0">
                        <div>
                          Applied filters: <span className="font-medium">{filter}</span>
                        </div>
                        <div
                          className="bg-black text-center py-0.5 px-2.5
                   text-white rounded-sm font-medium cursor-pointer"
                          onClick={clearFilter}
                        >
                          Clear X
                        </div>
                      </div>
                    )
                    : (
                      ''
                    )}
                </Col>
                <Col xs={24} xl={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-full font-bold"
                      disabled={fromDate.length === 0 || toDate.length === 0}
                    >
                      <div className="float-left">
                        <i className="fa-file-excel"></i>
                      </div>
                      FILTER
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
        <Card title="Bailiffs & Bidders Refund Request Status" style={{ marginTop: '12px' }}>
          <Tabs
            onChange={(key) => handleTabChange(key)}
            type="card"
            size="large"
            defaultActiveKey="bidders"
            tabBarExtraContent={(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ZoomOutOutlined /> */}
                <Input
                  size="large"
                  placeholder="Quick search in the tables"
                  prefix={<ZoomOutOutlined />}
                  onChange={OnRefundRequestSearch}
                  style={{ width: '400px', marginRight: '8px' }}
                />
                <Button style={{ marginRight: '12px' }} onClick={handleDownloadExcelFile}>
                  Download Report
                </Button>
                <Tag color="#108ee9">Selected {filter}</Tag>
                <Button
                  type="link"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear Filter
                </Button>
              </div>
            )}
          >
            <Tabs.TabPane tab="Bidders" key="bidders">
              <Spin spinning={loading} indicator={antIcon} tip="Loading...">
                <Table
                  columns={biddersColumns}
                  dataSource={refundRequestList}
                  onChange={handleChange}
                  pagination={paginating}
                  loading={loading}
                />
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bailiffs" key="bailiffs">
              <Spin spinning={loading} indicator={antIcon} tip="Loading...">
                <Table
                  columns={bailiffsColumns}
                  dataSource={bailiffRefundRequestList}
                  onChange={handleChange}
                  pagination={paginating}
                  loading={loading}
                />
              </Spin>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default BiddersRefundRequestStatus;
