import React from 'react'
import '../../assets/css/custom.css'
import MTN from '../../assets/img/mtn.svg'
import airtel from '../../assets/img/airtel.svg'
// import cards from '../../assets/img/cards.svg'
import minijust_logo from '../../assets/img/minijust_logo.png'
import UP from '../../assets/img/poweredbyUP.svg'
import UPG from '../../assets/img/new-logo-blue.svg'
import BK from '../../assets/img/bk.svg'
import {
  AppstoreOutlined,
  MailOutlined,
  GlobalOutlined,
  ExclamationCircleFilled,
  StarFilled
} from '@ant-design/icons'
import { type MenuProps, Menu, Card, Button } from 'antd'
import { useParams } from 'react-router-dom'

const items: MenuProps['items'] = [
  {
    label: 'PAY DIRECTLY ONLINE',
    key: 'online',
    icon: <MailOutlined />
  },
  {
    label: 'GET DEPOSIT CODE',
    key: 'bank',
    icon: <AppstoreOutlined />
  }
]

const ChooseChannel: React.FC = () => {
  const [current, setCurrent] = React.useState('online')
  const { mcode, pcode } = useParams()
  const origin = window.location.origin
  const { REACT_APP_UPG_URL } = process.env

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  }

  const url = `${REACT_APP_UPG_URL as string}/pay-now/initiate?mhcd=${mcode as string}&pycd=${
    pcode as string
  }&rdurl=${origin}/receipt/${pcode as string}`

  return (
    <div className="w-full p-body">
      <div className="navbar pay-bot-border">
        <div className="flex">
          <div className="nav-logo">
            <img src={minijust_logo} alt="Logo" />
          </div>
          <div className="nav-content text-sm md:text-2xl">
            <div className="nav-content-header">MINIJUST - IECMS</div>
            <div>Bid Security Online Payment</div>
          </div>
        </div>
        <div className="items-center font-semibold text-xs">
          <GlobalOutlined className="nav-globe" />
          <div>ENGLISH</div>
        </div>
      </div>

      <div className="p-10">
        <Menu
          inlineIndent={800}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          className="focus:text-green-500"
          style={{ marginTop: 80 }}
        />
        <div>
          {current === 'online' && (
            <div className="flex flex-col justify-center pt-12 items-center">
              <div
                className="font-bold text-base flex text-center
            items-center justify-center leading-10 p-4 "
              >
                Click here to pay using one the following methods
              </div>
              <div className="flex w-1/4 justify-around mt-14 mb-24">
                <Button type="ghost" size="large" href={url}>
                  <img src={MTN} alt="" className=" w-30 h-16 object-cover rounded-lg" />
                </Button>
                <Button type="ghost" size="large" href={url}>
                  <img src={airtel} alt="" className="w-30 h-16 object-cover rounded-lg" />
                </Button>
              </div>
              <div>
                <img src={UP} alt="" />
              </div>
            </div>
          )}

          {current === 'bank' && (
            <>
              <div className="flex flex-col justify-between ">
                <div
                  className="font-extrabold text-lg flex text-center
            items-center justify-center leading-10 p-4 "
                >
                  Follow these steps to pay via a BK teller.
                </div>
                <div className="flex justify-around my-14">
                  <Card className=" text-center">
                    <p className=" text-lg font-bold text-[#008634]">Step one</p>
                    <p className="py-4">Go to any nearest BK branch</p>
                    <img src={BK}/>
                  </Card>
                  <Card className=" text-center">
                    {' '}
                    <p className=" text-lg font-bold text-[#008634]">Step two</p>{' '}
                    <p className="py-4">Request to pay with Urubuto Pay</p>
                    <img src={UPG}/>
                  </Card>
                  <Card className=" text-center">
                    <p className=" text-lg font-bold text-[#008634]">Step three</p>{' '}
                    <p className="py-4">Provide this code to the teller</p>
                    <p className="font-bold">
                      {mcode}/{pcode}
                    </p>
                  </Card>
                </div>
              </div>

              <div className="flex flex-col items-center mt-24" style={{ border: '0px solid blue', marginTop: '-10px' }}>
                <p className="text-red-600 text-lg text-center mt-2">
                  <h2 className="text-xl font-bold text-blue-600">Service Delivery Rating</h2>
                  <div className="flex items-center mt-2">
                    <span className="text-yellow-400 text-xl"><StarFilled/></span>
                    <span className="text-yellow-400 text-xl ml-1"><StarFilled/></span>
                    <span className="text-yellow-400 text-xl ml-1"><StarFilled/></span>
                    <span className="text-yellow-400 text-xl ml-1"><StarFilled/></span>
                    <span className="text-yellow-400 text-xl ml-1"><StarFilled/></span>
                  </div>
                  <b>
                    Click here{' '}
                    <a
                      href="https://t.ly/e5y4b"
                      className="text-blue-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      to leave feedback(Siga Ubutumwa)
                    </a>
                  </b>
                  <br/>
                </p>
              </div>

              <div className=" flex justify-center items-start mt-24 " style={{
                border: '0px solid blue',
                marginTop: '5px'
              }}>
                <ExclamationCircleFilled className="text-red-600 text-xl"/>
                <p className=" text-red-600 text-lg ml-2 text-center">
                  Note: After the deposit you will receive an email notification with a reference
                  number. <br/>
                  Use the reference number you found to verify at <b>www.cyamunara.rw</b>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChooseChannel
