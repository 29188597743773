import React, { useState, useEffect } from 'react'
import '../../assets/css/custom.css'
import jwt_decode from 'jwt-decode'
import { Col, Row, Button, Form, Input, message } from 'antd'
import { GlobalOutlined, NumberOutlined } from '@ant-design/icons'
import minijust_logo from '../../assets/img/minijust_logo.png'
import { useNavigate } from 'react-router-dom'
import InstitutionUserService from '../../services/InstitutionUserService'
import CryptoJS from 'crypto-js'
import moment from 'moment'

const OTP: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [countdown, setCountdown] = useState(300000)

  useEffect(() => {
    const e_mail = localStorage.getItem('email')
    const tel = localStorage.getItem('phone_number')

    if (e_mail !== null && tel !== null) {
      setEmail(e_mail)
      const decryptedPhone = CryptoJS.AES.decrypt(tel, 'secret-key').toString(CryptoJS.enc.Utf8)
      setPhone(decryptedPhone)
    }

    return () => {
      localStorage.removeItem('email')
      localStorage.removeItem('phone_number')
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer)
          return 0
        }
        return prevCountdown - 1000
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const onVerifyOTP = (values: any): void => {
    setLoading(true)
    InstitutionUserService.VerifyOTP({ email, otp: values.otp })
      .then((res: any) => {
        const decoded: any = jwt_decode(res?.token)
        decoded.token = res?.token
        localStorage.setItem('userInfo', JSON.stringify(decoded))
        decoded?.role === 'SYSTEM ADMIN'
          ? navigate('/dashboard')
          : decoded?.role === 'MINIJUST FINANCE'
            ? navigate('/finance-dashboard')
            : navigate('/institution-dashboard')
        window.location.reload()

        setLoading(false)
      })
      .catch((err: any) => {
        setErrorMsg(err?.response?.data?.message)
        void message.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  return (
    <div className="w-full">
      <div className="navbar">
        <div className="flex">
          <div className="nav-logo">
            <img src={minijust_logo} alt="Logo" />
          </div>
          <div className="nav-content text-sm md:text-2xl">
            <div className="nav-content-header">MINIJUST - IECMS</div>
            <div>Bid Security Management Information System</div>
          </div>
        </div>
        <div className="items-center font-semibold text-xs">
          <GlobalOutlined className="nav-globe" />
          <div>ENGLISH</div>
        </div>
      </div>

      <div className="login-content">
        <Row justify={'space-around'} className="mb-8 mt-[10%]">
          <Col md={8}>
            <div className="login-title">
              <p>Enter the OTP to complete login</p>
            </div>
            <p className="text-center text-red-600 pb-1">{errorMsg?.toUpperCase() ?? ''}</p>

            <Form
              name="normal_login"
              className="login-form form-pad"
              initialValues={{ remember: true }}
              onFinish={onVerifyOTP}
              autoComplete="off"
            >
              <label className="input-label">OTP</label>
              <Form.Item
                name="otp"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input
                  prefix={<NumberOutlined className="site-form-item-icon" />}
                  placeholder="000000"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  loading={loading}
                  className="w-full"
                >
                  Verify
                </Button>
              </Form.Item>
              <p>
                We just sent you an SMS on this phone number: ********{phone.slice(-2)} with an OTP
                (One Time Password). Enter the OTP in the form above to verify your identity.{' '}
              </p>
              <br />
              <p>
                The OTP will expire in 5 minutes
                <code className="font-semibold ml-2">
                  {moment(countdown).format('mm:ss')}</code>.{' '}
                {countdown === 0 && <Button type="link">Click here to resend SMS.</Button>}
              </p>
            </Form>
          </Col>
        </Row>

        <Row className="p-3 fixed bottom-0 left-0 right-0 h-12">
          <Col md={14}>
            <div className="green-color">
              <span
                onClick={() => {
                  navigate('/')
                }}
                className="pr-8"
              >
                Home
              </span>
              <span className="pr-8">About the System</span>
              <span className="pr-8">FAQ</span>
              <span className="pr-8">Support</span>
              <span className="pr-8">Terms and Conditions</span>
              <span className="pr-8">Privacy and Policy</span>
            </div>
          </Col>
          <Col md={10}>
            <div className="float-right text-gray-500">
              Copyright@2023 MINIJUST All rights reserved.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default OTP
