import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import moment from 'moment'
import { message } from 'antd'

export const downloadExcel = (
  columns: string[],
  data: Array<Record<string, any>>,
  fileName: string
): void => {
  const filteredData = data?.map((item) =>
    columns.reduce((acc, key) => ({ ...acc, [key]: item[key] }), {})
  )

  // Create a new workbook
  const workbook = XLSX.utils.book_new()

  // Convert data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData)

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'FilteredData')

  // Generate a blob and save the file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  FileSaver.saveAs(excelBlob, `${moment().format('DD-MM-YYYY, hh:mm:ss')} - ${fileName}.xlsx`)

  void message.success('Data downloaded successfully!')
}
