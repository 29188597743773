/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Form, Select, Space, Input, Modal } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import InstitutionUserService from '../../services/InstitutionUserService'

const { Option } = Select

const EditRefundRequestStatus = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const refundRequest = state || {}
  const bailiffRefundRequest = state || {}
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [statusData, setStatusData] = useState<any>({})
  const [isEdit, setIsEdit] = useState(false)
  const [editStatusID, setEditStatusID] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const location = useLocation()
  const formatDate = ({ dateString }: { dateString: any }) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (location.state != null) {
      const { status_id, status, message } = location?.state
      setIsEdit(true)
      setEditStatusID(status_id)
      form.setFieldsValue({ status, message })
    }
  }, [form, location.state])

  const showModal = (): void => {
    setIsModalOpen(true)
  }

  // Asynchronous operation wrapped in a regular function
  const handleModalOk = (): void => {
    setLoading(true)
    updateRefundStatus()
      .then(() => {
        setError(null)
        Modal.success({
          title: 'Success',
          content: 'The refund request status has been updated successfully.'
        })
        navigate('/bank-transfer-update-status') // Redirect to another page if needed
      })
      .catch(() => {
        setError('Failed to update refund request status.')
        Modal.error({
          title: 'Error',
          content: 'Failed to update refund request status. Please try again.'
        })
      })
      .finally(() => {
        setLoading(false)
        setIsModalOpen(false)
      })
  }

  const updateRefundStatus = async (): Promise<void> => {
    try {
      const payload = {
        RefundRequests: [
          {
            transaction_reference: refundRequest.transaction_reference,
            phone_number: refundRequest.phone_number
          }
        ],
        status: form.getFieldValue('status')
      }
      await InstitutionUserService.editRefundRequestStatusMessage(payload)
    } catch (err) {
      throw new Error('Failed to update refund request status.')
    }
  }

  const handleModalCancel = (): void => {
    setIsModalOpen(false)
  }

  const onFinish = (values: any): void => {
    showModal()
    setStatusData(values)
  }
  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate('/refund-request-list')
              }}
            >
              Refund Request
            </span>{' '}
            {'> '}
            <span className="font-normal">{refundRequest?.beneficiary_account_name}</span>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Beneficiary Refund Request Details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_account_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Bank Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_bank_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account number</div>
              <div
                className="text-base font-medium">{refundRequest?.beneficiary_account_number}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Number</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Name</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_name}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction Reference</div>
                <div className="text-base font-medium">{refundRequest?.transaction_reference}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount to be refunded</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.amount)).toLocaleString()}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction currency</div>
                <div className="text-base font-medium">{refundRequest?.transaction_currency}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Reason</div>
                <div className="text-base font-medium">{refundRequest?.reason}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="custom-col-container">
                  <div className="custom-title">Status</div>
                  <div className="custom-status">{refundRequest?.status}</div>
                </div>
              </Col>

              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Requested Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.created_at })}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Updated Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.updated_at })}</div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Phone Number</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.phone_number)).toLocaleString()}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount to be refunded</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.amount)).toLocaleString()}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount to be refunded</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.amount)).toLocaleString()}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Update Refund Request Status
          </div>
          <div className="pt-4">
            <Form
              name="status_form"
              layout="vertical"
              className="status-form p-10"
              initialValues={{ message: "Your refund status has been updated to: Bank Transfer Failed. Any Assistance contact us on iecms-support" }}
              onFinish={onFinish}
              form={form}
            >
              <Space direction="vertical" size={5} className="w-full">
                <Card className="card-border h-full">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} xl={12}>
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'Please select a status' }]}
                      >
                        <Select placeholder="Select Status">
                          <Option value="Bank Transfer Failed">Bank Transfer Failed</Option>
                          <Option value="RECEIVED REQUEST">RECEIVED REQUEST</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={[16, 16]}> */}
                  {/*   <Col xs={24} md={24} xl={24}> */}
                  {/*     <Form.Item */}
                  {/*       name="message" */}
                  {/*       label="Message" */}
                  {/*       rules={[{ required: true, message: 'Please enter a message' }]} */}
                  {/*     > */}
                  {/*       <Input.TextArea  rows={4} /> */}
                  {/*     </Form.Item> */}
                  {/*   </Col> */}
                  {/* </Row> */}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-button"
                      loading={loading}
                    >
                      {isEdit ? 'Update' : 'Submit'}
                    </Button>
                  </Form.Item>
                </Card>
              </Space>
            </Form>

            <Modal
              title={isEdit ? 'Update Status' : 'Submit Status'}
              open={isModalOpen}
              onOk={handleModalOk} // The function provided here should return void
              onCancel={handleModalCancel}
              confirmLoading={loading}
            >
              <p>Are you sure you want to {isEdit ? 'update' : 'submit'} this status?</p>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRefundRequestStatus
