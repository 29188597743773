import { message } from 'antd'
import axios, { type AxiosRequestConfig, type AxiosResponse, type AxiosError } from 'axios'

const { REACT_APP_API_BASE_URL } = process.env

const instance: any = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    // 'Access-Control-Allow-Origin': 'https://training.iecms.gov.rw/oa-de/',
    // 'Access-Control-Allow-Methods': 'GET, HEAD, POST'
  }
})

// Add a request interceptor
instance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const userInfo =
      localStorage.getItem('userInfo') !== null
        ? (localStorage.getItem('userInfo') as string)
        : '{}'

    const parsedUserInfo = JSON.parse(userInfo)
    const token = parsedUserInfo.token
    if (token !== undefined && config.headers !== undefined) config.headers.Authorization = token
    return { ...config }
  },
  async function (error: unknown) {
    // Do something with request error
    return await Promise.reject(error)
  }
)

const responseBody = (response: Record<string, unknown>): void => {
  const resp: any = response.data
  if (resp.message === 'Invalid Token') {
    localStorage.removeItem('userInfo')
    window.location.href = '/'
  }
  return resp
}

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    const { response } = error
    if (response !== null && response?.status === 409) {
      try {
        await instance.post('/auth/logout', {})
        localStorage.removeItem('userInfo')
        window.location.href = '/'
      } catch (err) {
        void message.error('Error logging out of the server')
      }
    }
    return await Promise.reject(error)
  }
)

const requests = {
  get: async (url: string, headers?: Record<string, unknown>) =>
    instance.get(url, headers).then(responseBody),

  post: async (url: string, body: unknown, headers?: Record<string, unknown>) =>
    instance.post(url, body, headers).then(responseBody),

  put: async (url: string, body: unknown, headers?: Record<string, unknown>) =>
    instance.put(url, body, headers).then(responseBody),

  patch: async (url: string, body: unknown) => instance.patch(url, body).then(responseBody),

  delete: async (url: string) => instance.delete(url).then(responseBody)
}

export default requests
