import React from 'react'
import '../../assets/css/custom.css'
import { Card, Col, Row, Button, message } from 'antd'
import { Chart } from 'react-google-charts'
import { useNavigate } from 'react-router-dom'
import Certificate from '../../assets/img/certificate.svg'
import CheckBook from '../../assets/img/check-book.svg'
import Money from '../../assets/img/money.svg'
import InstitutionUserService from '../../services/InstitutionUserService'
import {
  generateChartDataInstitution,
  generatePieChartInstitution
} from '../../utils/generateChartData'

export const data = [
  ['Bid Security', 'Hours per Day'],
  ['Bid guarantee', 11],
  ['Certified checks', 9]
  // ['Watch TV', 2],
  // ['Sleep', 7]
]

export const options = {
  title: 'Value of Bid Security Provided'
}

export const barData = [
  ['Months', 'Bid Guarantee', 'Certified checks'],
  ['JAN', 1000, 400],
  ['FEB', 1170, 460],
  ['MAR', 660, 1120],
  ['APR', 1030, 540],
  ['MAY', 100, 300],
  ['JUN', 1000, 400],
  ['JUL', 1170, 460],
  ['AUG', 660, 1120],
  ['SEPT', 1030, 540],
  ['OCT', 100, 300],
  ['NOV', 1030, 540],
  ['DEC', 100, 300]
]

export const barOptions = {
  chart: {
    title: 'Provided Bid Security per Month'
    // subtitle: 'Bid Guarantee, Certified checks - 2019-2023'
  }
}

const InstitutionDashboard: React.FC = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
  const navigate = useNavigate()
  const [stats, setStats] = React.useState<Record<string, any>>({})
  React.useEffect(() => {
    InstitutionUserService.InstitutionStats()
      .then((res: any) => {
        const { bid_guarantees, certified_checks, bar_data, total } = res?.data
        const pie = generatePieChartInstitution(
          bid_guarantees,
          certified_checks
        )
        const bar = generateChartDataInstitution(bar_data)
        setStats((prev) => ({
          ...prev,
          bid_guarantees,
          certified_checks,
          total,
          pie_chart: pie,
          bar_chart: bar
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  const createBidGuarantee = (): void => {
    navigate('/create-bid-guarantee')
  }

  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span>Dashboard</span>
          </div>
        </div>
      </div>

      <div className="p-7">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={Certificate} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-2.5 font-extrabold">
                  <div>Added</div>
                  <div>Bid Guarantees value</div>
                  <div className="text-3xl pt-3">
                    <p>{stats?.bid_guarantees?.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={CheckBook} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div>Added</div>
                  <div>Certified Checks value</div>
                  <div className="text-3xl pt-3">
                    <p>{stats?.certified_checks?.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={Money} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div>Total Value</div>
                  <div>of Provided Bid Security</div>
                  <div className="text-3xl pt-3">
                    <p>{stats?.total?.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center text-lg font-bold">Quick Action</div>
              <div className="p-3.5">
                {userInfo?.role === 'INSTITUTION USER' && (
                  <Button type="primary" className="w-full text-sm" onClick={createBidGuarantee}>
                    Add a bid security
                  </Button>
                )}
              </div>
              {userInfo?.role === 'INSTITUTION ADMIN' && (
                <div className="p-3.5">
                  <Button
                    type="primary"
                    className="w-full text-sm"
                    onClick={() => {
                      navigate('/create-users-accounts')
                    }}
                  >
                    Add another user
                  </Button>
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} xl={10}>
            <Card className="card-border h-full">
              <Chart
                chartType="PieChart"
                data={stats?.pie_chart}
                options={options}
                width={'100%'}
                height={'400px'}
              />
            </Card>
          </Col>
          <Col xs={24} xl={14}>
            <Card className="card-border h-full">
              <div className="text-center">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={stats?.bar_chart}
                  options={barOptions}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InstitutionDashboard
