interface Payment {
  month: string
  total_amount: string
}
interface AmoutRefunded {
  month: string
  total_refunded_amount: string
}
interface Document {
  month: string
  total_amount: string
}

interface Data {
  payments: Payment[]
  documents: Document[]
  amoutRefunded: AmoutRefunded[]
}

interface BG {
  month: string
  total_security_amount: string
}

interface CC {
  month: string
  total_security_amount: string
}

interface DataInstitution {
  bid_guarantees: BG[]
  certified_checks: CC[]
}
export function generateChartRefundDataMFinance(data: Data): Array<Array<string | number>> {
  const months: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]
  const barData: Array<Array<string | number>> = [
    ['Months', 'Refunded Amount']
  ]

  for (let i = 0; i < months.length; i++) {
    const month = months[i]
    const refund = data.amoutRefunded.find((p) => p.month === (i + 1).toString())
    const paymentAmount = refund != null ? parseInt(refund.total_refunded_amount) : 0

    barData.push([month, paymentAmount])
  }

  return barData
}

function generateChartDataMFinance(data: Data): Array<Array<string | number>> {
  const months: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]
  const barData: Array<Array<string | number>> = [
    ['Months', 'Bid Amount', 'Digital Payment Amount']
  ]

  for (let i = 0; i < months.length; i++) {
    const month = months[i]
    const payment = data.payments.find((p) => p.month === (i + 1).toString())
    const document = data.documents.find((d) => d.month === (i + 1).toString())
    const paymentAmount = payment != null ? parseInt(payment.total_amount) : 0
    const documentAmount = document != null ? parseInt(document.total_amount) : 0

    barData.push([month, paymentAmount, documentAmount])
  }

  return barData
}

function generateSAdminChartData(
  chartData: Array<Array<string | number>>
): Array<Array<string | number>> {
  const months: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]
  const barData: Array<Array<string | number>> = [
    ['Months', 'Bid Guarantee', 'Certified checks', 'Payments']
  ]

  for (let i = 0; i < months.length; i++) {
    const month = months[i]
    const dataRow = chartData.find((row) => row[0].toString().toUpperCase() === month)

    const bidGuarantee = dataRow != null ? dataRow[1] : 0
    const certifiedChecks = dataRow != null ? dataRow[2] : 0
    const payments = dataRow != null ? dataRow[3] : 0

    barData.push([month, bidGuarantee, certifiedChecks, payments])
  }

  return barData
}

function generatePieChartInstitution(bg: number, cc: number): Array<Array<string | number>> {
  return [
    ['Bid Security', 'Hours per Day'],
    ['Bid guarantee', bg],
    ['Certified checks', cc]
  ]
}

function generateChartDataInstitution(data: DataInstitution): Array<Array<string | number>> {
  const months: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]
  const barData: Array<Array<string | number>> = [['Months', 'Bid Guarantee', 'Certified checks']]

  for (let i = 0; i < months.length; i++) {
    const month = months[i]
    const bidGuarantees = data.bid_guarantees.find((p) => p.month === (i + 1).toString())
    const certifiedChecks = data.certified_checks.find((d) => d.month === (i + 1).toString())
    const paymentAmount =
      bidGuarantees != null ? parseInt(bidGuarantees.total_security_amount, 10) : 0
    const documentAmount =
      certifiedChecks != null ? parseInt(certifiedChecks.total_security_amount, 10) : 0

    barData.push([month, paymentAmount, documentAmount])
  }

  return barData
}

export {
  generateChartDataMFinance,
  generateSAdminChartData,
  generatePieChartInstitution,
  generateChartDataInstitution
}
