import React from 'react'
import { Col, Row, message, Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
const { REACT_APP_API_BASE_URL } = process.env

const ViewBidSecurities: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation().state
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')

  const handleDownloadFile = async (fileName: string): Promise<any> => {
    await fetch(`${REACT_APP_API_BASE_URL as string}/bid-security/download-file/${fileName}`,
      {
        headers: {
          'Content-Disposition': `attachment; filename=${fileName}`,
          Authorization: userInfo.token
        }
      })
      .then(async (response) => await response.blob())
      .then((blob) => {
        const file = new Blob([blob], { type: 'application/pdf' })
        window.open(URL.createObjectURL(file))
      })
      .catch(() => {
        void message.error('Error fetching PDF:')
      })
  }

  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate('/bid-securities-list')
              }}
            >
              Bid Security Details
            </span>{' '}
            {'> '}
            <span className="font-normal">{location?.full_name}</span>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-2.5">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Bidder details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder&apos;s ID Number</div>
              <div className="text-base font-medium">{location?.nid}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder&apos;s Name</div>
              <div className="text-base font-medium">{location?.full_name}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Bidder&apos;s Phone Number</div>
                <div className="text-base font-medium">{location?.phone_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Bidder&apos;s Email Address</div>
                <div className="text-base font-medium">{location?.email}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-2.5">
          <div className="green-color font-extrabold text-base flex items-center leading-6">
            Auction details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Execution Case Number</div>
              <div className="text-base font-medium">{location?.execution_case_no}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Property ID</div>
              <div className="text-base font-medium">{location?.property_id}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Expiration Date</div>
              <div className="text-base font-medium">
                {moment(location?.expiration_date).format('YYYY-MM-DD hh:mm')}
              </div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Bid Name</div>
                <div className="text-base font-medium">{location?.name}</div>
              </Col>
              <Col xs={24} xl={8}>{parseInt(String(location?.amount)).toLocaleString()}
                <div className="text-base font-bold">Bid Security Amount</div>
                <div className="text-base font-medium">{parseInt(String(location?.security_amount)).toLocaleString()}
                </div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Status</div>
                <div className="text-base font-medium">{location?.status}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-2.5">
          <div className="green-color font-extrabold text-base flex items-center leading-6">
            Bid Security Supplied
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bid Security Type</div>
              <div className="text-base font-medium">{location?.type}</div>
            </Col>
            {location?.type !== 'PAYMENT'
              ? <>
                <Col xs={24} xl={8}>
                  <div className="text-base font-bold">Download Document</div>
                  <Button
                    type="link"
                    className=' text-blue-600 hover:shadow'
                    onClick={() => {
                      void handleDownloadFile(location?.file)
                    }}
                  >
                    Click link download
                  </Button>
                </Col>
                <Col xs={24} xl={8}>
                  <div className="text-base font-bold">Document Valid Until</div>
                  <div className="text-base font-medium">

                    {moment(location?.expiration_date).format('YYYY-MM-DD hh:mm')}
                  </div>
                </Col>
                <Col>
                  <div className="text-base font-bold">
                    Added on
                  </div>
                  <div className='text-base font-medium'>
                    {moment(location?.created_at).format('YYYY-MM-DD hh:mm:ss')}
                  </div>
                </Col>
              </>
              : ''}
          </Row>
        </div>
      </div>
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate('/bid-securities-list')
              }}
            >
              Bid Security Payments Details
            </span>{' '}
            {'> '}
            <span className="font-normal">{location?.bank_name}</span>
          </div>
        </div>
      </div>
      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Payment details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bank Name</div>
              <div className="text-base font-medium">{location?.bank_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Account Number</div>
              <div className="text-base font-medium">{location?.bank_account}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Slip Number</div>
              <div className="text-base font-medium">
                {location?.slip_number.trim().length > 0 ? location.slip_number : 'N/A'}
              </div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Payment Channel</div>
                <div className="text-base font-medium">{location?.payment_channel}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Currency</div>
                <div className="text-base font-medium">{location?.currency}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount</div>
                <div className="text-base font-medium">{parseInt(String(location?.amount)).toLocaleString()}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Merchant Code</div>
                <div className="text-base font-medium">{location?.merchant_code}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Payment Channel Name</div>
                <div className="text-base font-medium">{location?.payment_channel_name}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction ID</div>
                <div className="text-base font-medium">{location?.id}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Payment Date</div>
                <div className="text-base font-medium">
                  {moment(location?.payment_date_time).format('YYYY-MM-DD')}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewBidSecurities
