import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
// import UserContext from '../../context/UserContext'

const PrivateRoute: React.FC = () => {
  // const { state } = useContext(UserContext);
  // const { userInfo } = state;

  // const userInfo = UserContext.userInfo

  const userInfo = (localStorage.getItem('userInfo') !== null)
    ? localStorage.getItem('userInfo') as string
    : '{}'

  const parsedUserInfo = JSON.parse(userInfo)
  const token = parsedUserInfo.token

  // const userInfo: any = localStorage.getItem('userInfo')
  //   ? JSON.parse(localStorage.getItem('userInfo') || '{}')
  //   : null

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token?.trim().length > 0 ? <Outlet /> : <Navigate to="/" />
}

export default PrivateRoute
