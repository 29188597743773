import React, { useEffect, useState } from 'react'
import {
  type TableProps,
  type TablePaginationConfig,
  message,
  Card,
  Col,
  Row,
  Form,
  Button,
  Table,
  Spin,
  Input,
  Select
} from 'antd'
import type { ColumnsType, SorterResult } from 'antd/es/table/interface'
import { LoadingOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import InstitutionUserService from '../../services/InstitutionUserService'
import { downloadExcel } from '../../utils/downloadExcel'
import moment from 'moment/moment'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#37762F' }} spin />
const { REACT_APP_API_BASE_URL } = process.env
interface DataType {
  key: React.Key
  created_at: string
  type: string
  ref_number: string
  full_name: string
  phone_number: string
  expiration_date: string
  status: string
}

const BidGuaranteesList: React.FC = () => {
  const [form] = Form.useForm()
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
  const navigate = useNavigate()
  const [bidGuaranteeList, setBidGuaranteeList] = useState([])
  const [clear, setClear] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({})
  const [paginating, setPaginating] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [propertyIDs, setPropertyIDs] = useState<string[]>([])
  const [statusBid, setStatusBid] = useState()
  const [selectedPID, setSeletedPID] = useState()

  useEffect(() => {
    OnBidSecuritySearch()
    // eslint-disable-next-line
  }, [clear, paginating.current, paginating.pageSize, statusBid, selectedPID])

  const OnBidSecuritySearch = (event?: any): void => {
    setLoading(true)

    if (userInfo.role === 'SYSTEM ADMIN' || userInfo.role === 'MINIJUST FINANCE') {
      InstitutionUserService.viewBidGuarantee(
        paginating.pageSize as number,
        paginating.current as number,
        event?.target?.value,
        statusBid,
        selectedPID
      )
        .then((res: any) => {
          if (res.status === 200) {
            setBidGuaranteeList(res?.data)
            const pIDSet = new Set<string>(
              res?.data.map((item: Record<string, any>) => item.property_id)
            )
            const uniquePIDs: string[] = Array.from(pIDSet)
            setPropertyIDs(uniquePIDs)
            setPaginating((prev) => ({
              ...prev,
              total: res?.meta?.total
            }))
            setLoading(false)
          }
        })
        .catch((err: any) => {
          if (err?.message !== 'Operation canceled due to new request.') {
            void message.error(err?.message)
          }
          setLoading(false)
        })
    } else if (userInfo.role === 'INSTITUTION ADMIN' || userInfo.role === 'INSTITUTION USER') {
      InstitutionUserService.GetBidSecurityListInstitutions(
        paginating.pageSize as number,
        paginating.current as number,
        event?.target?.value,
        statusBid,
        selectedPID
      )
        .then((res: any) => {
          if (res.status === 200) {
            setBidGuaranteeList(res?.data)
            const pIDSet = new Set<string>(
              res?.data.map((item: Record<string, any>) => item.property_id)
            )
            const uniquePIDs: string[] = Array.from(pIDSet)
            setPropertyIDs(uniquePIDs)
            setPaginating((prev) => ({
              ...prev,
              total: res?.meta?.total
            }))
            setLoading(false)
          }
        })
        .catch((err: any) => {
          if (err?.message !== 'Operation canceled due to new request.') {
            void message.error(err?.message)
          }
          setLoading(false)
        })
    }
  }

  const clearFilter = (): void => {
    form.resetFields()
    setSeletedPID(undefined)
    setStatusBid(undefined)
    setClear(true)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Bidder',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text: any, record: any) => <span>{record?.full_name}</span>,
      sorter: (a: any, b: any) => a?.full_name.localeCompare(b?.ref_number),
      sortOrder: sortedInfo.columnKey === 'full_name' ? sortedInfo.order : null,
      ellipsis: false
    },
    {
      title: 'Phone No',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text: any, record: any) => <span>{record?.phone_number}</span>,
      ellipsis: true
    },
    {
      title: 'Property ID',
      dataIndex: 'property_id',
      render: (text: any, record: any) => <span>{record?.property_id}</span>,
      key: 'property_id'
    },
    {
      title: 'Payer Code',
      dataIndex: 'payer_code',
      key: 'payer_code',
      render: (text: any, record: any) => <span>{record?.payer_code}</span>,
      sorter: (a: any, b: any) => a?.payer_code.localeCompare(b?.payer_code),
      sortOrder: sortedInfo.columnKey === 'ref_number' ? sortedInfo.order : null,
      ellipsis: false
    },
    {
      title: 'Payment Channel',
      dataIndex: 'payment_channel',
      key: 'payment_channel',
      render: (text: any, record: any) => <span>{record?.payment_channel}</span>,
      sorter: (a: any, b: any) => a?.payment_channel.localeCompare(b?.payment_channel),
      sortOrder: sortedInfo.columnKey === 'payment_channel' ? sortedInfo.order : null,
      ellipsis: true
    },
    {
      title: 'Payment Channel Name',
      dataIndex: 'payment_channel_name',
      key: 'payment_channel_name',
      render: (text: any, record: any) => <span>{record?.payment_channel_name}</span>,
      sorter: (a: any, b: any) => a?.payment_channel_name.localeCompare(b?.payment_channel_name),
      sortOrder: sortedInfo.columnKey === 'payment_channel_name' ? sortedInfo.order : null,
      ellipsis: true
    },
    {
      title: 'Ref Number',
      dataIndex: 'ref_number',
      key: 'ref_number',
      render: (text: any, record: any) => <span>{record?.ref_number}</span>,
      sorter: (a: any, b: any) => a?.ref_number.localeCompare(b?.ref_number),
      sortOrder: sortedInfo.columnKey === 'ref_number' ? sortedInfo.order : null,
      ellipsis: false
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_date_time',
      key: 'payment_date_time',
      render: (text: any, record: any) => (
        <span>{moment(record?.payment_date_time).format('YYYY-MM-DD HH:MM:SS')}</span>
      ),
      sorter: (a: any, b: any) => moment(a?.payment_date_time).unix() - moment(b?.payment_date_time).unix(),
      sortOrder: sortedInfo.columnKey === 'payment_date_time' ? sortedInfo.order : null,
      ellipsis: false
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (text: any, record: any) => (
        <div>
          <i
            className="far fa-eye cursor-pointer"
            onClick={() => {
              navigate('/view-bid-guarantees', { state: record })
            }}
          ></i>
          &nbsp;&nbsp;
          {userInfo?.role === 'INSTITUTION USER' &&
            record?.type !== 'PAYMENT' &&
            record?.used === false && (
              <>
                <i
                  className="fas fa-edit cursor-pointer"
                  onClick={() => {
                    editBidSecurity(record)
                  }}
                ></i>
                &nbsp;&nbsp;
              </>)}
          &nbsp;&nbsp;<i className="las la-file-prescription cursor-pointer text-base"></i>
        </div>
      )
    }
  ]

  const editBidSecurity = (record: any): void => {
    navigate('/create-bid-guarantee', { state: record })
  }

  const handleChange: TableProps<DataType>['onChange'] = (pagination, _filters, sorter) => {
    setPaginating(pagination)
    setSortedInfo(sorter as SorterResult<DataType>)
  }

  const createInstitution = (): void => {
    navigate('/create-bid-guarantee')
  }

  const onPIDSelect = (event: any): void => {
    setSeletedPID(event)
  }

  const onStatusSelect = (event: any): void => {
    setStatusBid(event)
  }

  const columnsToDownload = [
    'full_name',
    'nid',
    'phone_number',
    'email',
    'created_at',
    'execution_case_no',
    'property_id',
    'name',
    'security_amount',
    'expiration_date',
    'ref_number',
    'type',
    'doc_number',
    'bs_expiration_date'
  ]

  const handleClearSelect = (field: string): void => {
    if (field === 'property') {
      setSeletedPID(undefined)
    } else if (field === 'status') {
      setStatusBid(undefined)
    }
  }

  return (
    <div className="w-full">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">Bid Guarantee</div>
          {userInfo?.role === 'INSTITUTION USER' && (
            <div className="md:w-1/5">
              <Button
                type="primary"
                size="large"
                className="w-full rounded-sm items-center text-white bg-green-color"
                onClick={() => {
                  createInstitution()
                }}
              >
                <div className="float-left">
                  <i className="fa-solid fa-plus-minus"></i>&nbsp;
                </div>
                <div className="text-base">Add Bid Security</div>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="px-7">
        <div className="py-4">
          <Form
            name="normal_login"
            layout="vertical"
            className="login-form p-10"
            initialValues={{ remember: true }}
            onFinish={clearFilter}
            form={form}
          >
            <Card className="bg-slate-100">
              <Row gutter={16}>
                <Col xs={24} xl={9}>
                  <Form.Item name="property">
                    <Select
                      showSearch
                      onChange={onPIDSelect}
                      size="large"
                      value={selectedPID}
                      placeholder="Filter by Property ID"
                      allowClear
                      onClear={() => { handleClearSelect('property') }}
                    >
                      {propertyIDs.map((pid) => (
                        <Select.Option key={pid} value={pid}>
                          {pid}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={9}>
                  <Form.Item name="status">
                    <Select
                      onChange={onStatusSelect}
                      size="large"
                      value={statusBid}
                      placeholder="Filter by Bid Status "
                      allowClear
                      onClear={() => { handleClearSelect('status') }}
                    >
                      <Select.Option value="WON">Won</Select.Option>
                      <Select.Option value="LOST">Lost</Select.Option>
                      <Select.Option value="ONGOING">Ongoing</Select.Option>
                    </Select>
                  </Form.Item>
                  {selectedPID !== undefined && statusBid !== undefined
                    ? (
                      <div className="flex justify-between pb-2.5 md:pb-0">
                        <div>
                          Applied filters:{' '}
                          <span className="font-medium">
                          {selectedPID}, {statusBid}
                        </span>
                        </div>
                        <div
                          className="bg-black text-center py-0.5 px-2.5
                   text-white rounded-sm font-medium cursor-pointer"
                          onClick={clearFilter}
                          // htmlType="submit"
                        >
                          Clear X
                        </div>
                      </div>)
                    : ('')}
                </Col>
                <Col xs={24} xl={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-full font-bold"
                      onClick={() => {
                        downloadExcel(columnsToDownload, bidGuaranteeList, 'bid securities')
                      }}
                      disabled={selectedPID === undefined || statusBid === undefined}
                    >
                      <div className="float-left">
                        <i className="fa-file-excel"></i>
                      </div>
                      DOWNLOAD
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
        <Card className="bg-white">
          <div className="pt-2.5">
            <div className="flex justify-between">
              <div className="text-xl font-semibold">List of Provided Bid Guarantee </div>
              <div className="md:w-1/4">
                <Input
                  size="large"
                  placeholder="Quick search in the tables"
                  prefix={<ZoomOutOutlined />}
                  onChange={(e) => {
                    OnBidSecuritySearch(e)
                  }}
                />
              </div>
            </div>
            <div className="pt-2.5 overflow-auto">
              {loading
                ? (<div className="text-center justify-center align-middle py-4">
                  <Spin indicator={antIcon} />
                </div>)
                : (
                  <Table
                    columns={columns}
                    dataSource={bidGuaranteeList}
                    pagination={paginating}
                    onChange={handleChange}
                    size="small"
                  />)}
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default BidGuaranteesList
