import React, { useState } from 'react'
import { Modal, Col, Row, Button, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import InstitutionUserService from '../../services/InstitutionUserService'

const InstitutionModel: React.FC<any> = (props: any) => {
  const navigate = useNavigate()
  const { institution } = props
  const [loading, setLoading] = useState(false)

  const handleOk = (): void => {
    const payload = {
      institution: {
        type: institution.institution_type,
        business_reg_no: institution.business_registration_number,
        name: institution.institution_name,
        abbreviation: institution.institution_official_abbreation,
        i_phone: institution.institution_phone_number,
        i_email: institution.institution_official_email_address
      },
      user: {
        nid: institution?.user_national_id_number?.toString(),
        name: institution?.initial_user_full_name,
        phone_number: institution?.initial_user_phone_number,
        email: institution?.initial_user_email_address
      }
    }

    if (institution?.isEdit === false) {
      setLoading(true)
      InstitutionUserService.CreateInstitution(payload)
        .then((res: any) => {
          setLoading(false)
          props.setIsModalOpen(false)
          navigate('/institutions-list')
          Modal.success({
            content: 'Successfully Added'
          })
        })
        .catch((err: any) => {
          setLoading(false)
          void message.error(err?.response?.data?.message)
          props.setIsModalOpen(false)
        })
    } else {
      setLoading(true)
      InstitutionUserService.EditInstitution(institution?.editInstitutionID, payload.institution)
        .then((res: any) => {
          setLoading(false)
          props.setIsModalOpen(false)
          navigate('/institutions-list')
          Modal.success({
            content: 'Successfully Updated'
          })
        })
        .catch((err: any) => {
          setLoading(false)
          void message.error(err?.response?.data?.message)
          props.setIsModalOpen(false)
        })
    }
  }

  const handleCancel = (): void => {
    props.setIsModalOpen(false)
  }

  return (
    <div>
      <Modal
        title="Verify & Confirm"
        open={props.isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className="top-5"
        width={1000}
        footer={[
          <Button
            key="submit"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        ]}
      >
        <div>
          <div>
            <p className="text-base font-medium pb-2 leading-7">
              Please verify and confirm the following details
            </p>
          </div>

          <div>
            <div className="bg-slate-100 p-2.5">
              <div className="green-color font-extrabold text-base flex items-center leading-10">
                Institution details
              </div>
              <Row gutter={16}>
                <Col xs={24} xl={8}>
                  <div className="text-base font-bold">Institution Type</div>
                  <div className="text-base font-medium">{institution.institution_type}</div>
                </Col>
                <Col xs={24} xl={8}>
                  <div className="text-base font-bold">Institution Business Registration</div>
                  <div className="text-base font-medium">
                    {institution.business_registration_number}
                  </div>
                </Col>
                <Col xs={24} xl={8}>
                  <div className="text-base font-bold">Institution Name</div>
                  <div className="text-base font-medium">{institution.institution_name}</div>
                </Col>
              </Row>
              <div className="pt-5">
                <Row gutter={16}>
                  <Col xs={24} xl={8}>
                    <div className="text-base font-bold">Institution Official Abbreviation</div>
                    <div className="text-base font-medium">
                      {institution.institution_official_abbreation}
                    </div>
                  </Col>
                  <Col xs={24} xl={8}>
                    <div className="text-base font-bold">Institution Phone Number</div>
                    <div className="text-base font-medium">
                      {institution.institution_phone_number}
                    </div>
                  </Col>
                  <Col xs={24} xl={8}>
                    <div className="text-base font-bold">Institution Email Address</div>
                    <div className="text-base font-medium">
                      {institution.institution_official_email_address}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {institution?.isEdit === false
            ? (
            <div className="pt-5">
              <div className="bg-slate-100 p-2.5">
                <div className="green-color font-extrabold text-base flex items-center leading-10">
                  Initial user details
                </div>
                <Row gutter={16}>
                  <Col xs={24} xl={8}>
                    <div className="text-base font-bold">User&lsquo;s National ID details</div>
                    <div className="text-base font-medium">
                      {institution.user_national_id_number}
                    </div>
                  </Col>
                  <Col xs={24} xl={8}>
                    <div className="text-base font-bold">User Names</div>
                    <div className="text-base font-medium">
                      {institution.initial_user_full_name}
                    </div>
                  </Col>
                </Row>
                <div className="pt-5">
                  <Row gutter={16}>
                    <Col xs={24} xl={8}>
                      <div className="text-base font-bold">User Phone Number</div>
                      <div className="text-base font-medium">
                        {institution.initial_user_phone_number}
                      </div>
                    </Col>
                    <Col xs={24} xl={8}>
                      <div className="text-base font-bold">User Email Address</div>
                      <div className="text-base font-medium">
                        {institution.initial_user_email_address}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
              )
            : (
                ''
              )}
        </div>
      </Modal>
    </div>
  )
}

export default InstitutionModel
